*,
*::after,
*::before {
  box-sizing: border-box;
}

.loader-container {
  margin: auto;
  position: relative;
  width: 250px;
  height: 250px;
  animation: container-turn 15000ms linear infinite;
}

.ring {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 10px solid black;
  position: relative;
  z-index: 5;
  box-shadow: inset 0px 0px 0px 2px white, 0px 0px 0px 2px white;
}

.ball-context-layer {
  position: absolute;
  inset: 0;
  animation: ball-layer-change 1500ms ease-in-out infinite;
}

.ball-container {
  position: absolute;
  inset: 0;
  animation: container-turn 15000ms linear infinite;
}

.ball {
  position: absolute;
  top: 50%;
  left: -5px;
  width: 20px;
  height: 20px;
}

.ball::after {
  content: "";
  background-color: var(--accent-color);
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--dark-primary-color);

  opacity: 0;
  animation: ball-move 1500ms ease-in-out infinite, fade-in 300ms forwards;
}

/* Generate the context layers with delayed animations */
.ball-context-layer:nth-of-type(1),
.ball-context-layer:nth-of-type(1) .ball-container,
.ball-context-layer:nth-of-type(1) .ball,
.ball-context-layer:nth-of-type(1) .ball::after {
  animation-delay: 0.25s !important;
}
.ball-context-layer:nth-of-type(2),
.ball-context-layer:nth-of-type(2) .ball-container,
.ball-context-layer:nth-of-type(2) .ball,
.ball-context-layer:nth-of-type(2) .ball::after {
  animation-delay: 0.5s !important;
}
.ball-context-layer:nth-of-type(3),
.ball-context-layer:nth-of-type(3) .ball-container,
.ball-context-layer:nth-of-type(3) .ball,
.ball-context-layer:nth-of-type(3) .ball::after {
  animation-delay: 0.75s !important;
}
.ball-context-layer:nth-of-type(4),
.ball-context-layer:nth-of-type(4) .ball-container,
.ball-context-layer:nth-of-type(4) .ball,
.ball-context-layer:nth-of-type(4) .ball::after {
  animation-delay: 1s !important;
}
.ball-context-layer:nth-of-type(5),
.ball-context-layer:nth-of-type(5) .ball-container,
.ball-context-layer:nth-of-type(5) .ball,
.ball-context-layer:nth-of-type(5) .ball::after {
  animation-delay: 1.25s !important;
}
.ball-context-layer:nth-of-type(6),
.ball-context-layer:nth-of-type(6) .ball-container,
.ball-context-layer:nth-of-type(6) .ball,
.ball-context-layer:nth-of-type(6) .ball::after {
  animation-delay: 1.5s !important;
}
.ball-context-layer:nth-of-type(7),
.ball-context-layer:nth-of-type(7) .ball-container,
.ball-context-layer:nth-of-type(7) .ball,
.ball-context-layer:nth-of-type(7) .ball::after {
  animation-delay: 1.75s !important;
}
.ball-context-layer:nth-of-type(8),
.ball-context-layer:nth-of-type(8) .ball-container,
.ball-context-layer:nth-of-type(8) .ball,
.ball-context-layer:nth-of-type(8) .ball::after {
  animation-delay: 2s !important;
}
.ball-context-layer:nth-of-type(9),
.ball-context-layer:nth-of-type(9) .ball-container,
.ball-context-layer:nth-of-type(9) .ball,
.ball-context-layer:nth-of-type(9) .ball::after {
  animation-delay: 2.25s !important;
}
.ball-context-layer:nth-of-type(10),
.ball-context-layer:nth-of-type(10) .ball-container,
.ball-context-layer:nth-of-type(10) .ball,
.ball-context-layer:nth-of-type(10) .ball::after {
  animation-delay: 2.5s !important;
}

@keyframes ball-turn {
  from {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes container-turn {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}

@keyframes ball-move {
  0% {
    transform: translateX(-100%);
    scale: 0.9;
  }
  50% {
    transform: translateX(100%);
    scale: 0.9;
  }
  75% {
    scale: 1.1;
  }
  100% {
    transform: translateX(-100%);
    scale: 0.9;
  }
}

@keyframes ball-layer-change {
  from {
    z-index: 0;
  }
  to {
    z-index: 10;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.loading-wrapper {
  margin: 10rem auto;
  text-align: center;
}
.loading-text {
  font-size: 3rem;
  background: linear-gradient(
    to right,
    var(--dark-accent-color),
    var(--dark-primary-color),
    var(--light-primary-color)
  );
  background-clip: text;
  color: transparent;
  font-weight: bold;
  display: inline-block;
  animation: highlightPoint 2s ease-in infinite alternate;
}
