/* comp-styles/DesCTA.css */
.desCTA-container {
  display: flex;
  padding: 5rem 1rem;
  margin: 0 auto;
  align-items: center;
}

.left-div,
.right-div {
  flex: 1;
}

.desCTA-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.desCTA-input {
  width: 80%;
  display: flex;
  border-radius: 3rem;
  margin: 0 auto;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  background-color: var(--light-primary-color);
}

.desCTA-input input {
  flex: 1;
  padding: 15px 20px;
  font-size: 1.5rem;
  border: none;
  outline: none;
  border-radius: 3rem;
}
.desCTA-input input::placeholder {
  font-size: 1.2rem;
}

.desCTA-input .desCTA-submit-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  border-radius: 50%;
}
.desCTA-input .desCTA-submit-button:hover {
  background-color: transparent;
  filter: drop-shadow(0 0 8px var(--dark-primary-color));
}

.desCTA-input .desCTA-submit-button img {
  width: 3.5rem;
  aspect-ratio: 1;
}

.desCTA-input .desCTA-submit-button img:hover {
  filter: drop-shadow(0 0 8px var(--dark-primary-color));
  animation: rotateAnimation 1s linear alternate-reverse;
}

.desCTA-input .error-message {
  color: var(--text-color);
  font-size: 0.8rem;
  font-weight: 800;
  letter-spacing: 0.2rem;
  margin: auto 0.5rem;
}

@keyframes rotateAnimation {
  0% {
    transform: rotate(360deg);
  }
  20% {
    transform: rotate(90deg);
  }
  40% {
    transform: rotate(180deg);
  }
  60% {
    transform: rotate(280deg);
  }
  80% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@media screen and (max-width: 768px) {
  .desCTA-container {
    flex-direction: column;
  }

  .desCTA-input .error-message {
    width: 7rem;
  }
}
@media screen and (max-width: 992px) {
  .desCTA-container {
    flex-direction: column;
  }

  .desCTA-input .error-message {
    width: 7rem;
  }
}
@media screen and (max-width: 576px) {
  .desCTA-input input {
    width: 60%;
  }
  .desCTA-input input::placeholder {
    font-size: 1rem;
  }

  .desCTA-input .error-message {
    width: 4.5rem;
    font-size: 0.5rem;
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .desCTA-input input {
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  .desCTA-input {
    width: 100%;
  }
}
@media screen and (max-width: 1260px) {
  .desCTA-input {
    width: 100%;
  }
}

.desCTA-input input:-webkit-autofill {
  background-color: var(--dark-primary-color) !important;
  -webkit-box-shadow: 0 0 0px 1000px var(--light-accent-color) inset;
  transition: background-color 5000s ease-in-out 0s;
}
