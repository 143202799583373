footer {
  background-color: var(--dark-primary-color);
  color: var(--light-primary-color);
  padding: 50px 50px;
  position: relative;
  text-align: center;
}

.footer-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
footer .footer-link-heads {
  -webkit-text-stroke: 1px var(--light-secondary-color);
  margin-bottom: 10px;
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

footer li a {
  font-size: 1.1rem;
  text-decoration: none;
  color: var(--light-accent-color);
  font-weight: bold;
}

.contact-info {
  flex: 1;
  font-size: 1.1rem;
  font-weight: bold;

  margin-bottom: 20px;
}

.contact-info p {
  margin: 5px 0;
}
.contact-info p:hover {
  transform: translate(0.5rem, -0.3rem) scale3d(1.5, 0.8, 1);
}
.contact-info .contact-info-links {
  text-decoration: none;
  color: var(--light-accent-color);
}
.contact-info .contact-info-links:hover {
  color: var(--light-accent-color);
  text-shadow: 0 10px 4px var(--light-primary-color);
}

.quick-links {
  flex: 1;
  margin-bottom: 20px;
}

.quick-links ul {
  list-style: none;
  padding: 0;
}

.quick-links ul li {
  margin-bottom: 5px;
}
.quick-links ul li:hover {
  transform: translate(0.5rem, -0.2rem) scale3d(2, 0.8, 1);
  text-shadow: 0 10px 4px var(--light-primary-color);
}

.social-media {
  flex: 1;
  margin-bottom: 20px;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}
.social-media .footer-link-heads {
  width: 100%;
}
.social-media ul {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12%;
  width: 100%;
  padding-right: 8%;
  padding-left: 8%;
}
.social-media ul li:hover {
  transform: scale(1.3);
}

.social-media ul li a {
  color: var(--light-accent-color);
  flex: 1;
  font-size: 24px;
}
.social-media ul li a:hover {
  color: var(--light-accent-color);
}

.newsletter {
  flex: 1;
  margin-bottom: 20px;
  text-align: center;
}

.input-wrapper {
  display: flex;
  justify-content: center;
  border-radius: 3rem;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  background-color: var(--light-primary-color);
}

.newsletter input[type="email"] {
  flex: 8;
  padding: 15px 20px;
  font-size: 1.5rem;
  border: none;
  outline: none;
  border-radius: 3rem;
}
.newsletter input::placeholder {
  font-size: 1.2rem;
}

.newsletter .footer-cta-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.newsletter .footer-cta-button:hover {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.newsletter .footer-cta-button img {
  width: 3.5rem;
  aspect-ratio: 1;
}
.newsletter .footer-cta-button img:hover {
  filter: drop-shadow(0 0 4px var(--dark-primary-color));
  animation: rotateAnimation 1s linear alternate-reverse;
}
footer .error-message {
  color: var(--dark-primary-color);
  margin: auto 1rem;
  font-size: 1rem;
  font-weight: 800;
}
@media screen and (max-width: 376px) {
  .footer-link-heads {
    font-size: 0.5rem;
    text-align: start;
  }
  .newsletter input {
    width: 60%;
    font-size: 1rem;
  }
  .newsletter input::placeholder {
    font-size: 1rem;
  }
  .contact-info p {
    display: flex;
    flex-wrap: wrap;
    font-size: 0.8rem;
  }
}
@media screen and (min-width: 377px) and (max-width: 577px) {
  .footer-link-heads {
    font-size: 1rem;
  }
  .newsletter input {
    width: 60%;
    font-size: 1rem;
  }
  .newsletter input::placeholder {
    font-size: 1rem;
  }
  .contact-info p {
    display: flex;
    flex-wrap: wrap;
    font-size: 0.8rem;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .newsletter input {
    width: 80%;
    font-size: 1rem;
  }
  .newsletter input::placeholder {
    font-size: 1rem;
  }
}

.legal-info {
  color: var(--light-accent-color);
  text-align: center;
}

.legal-info p {
  margin: 5px 0;
}
@media screen and (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-container > * {
    width: 100%;
  }
}

.newsletter,
.contact-info,
.quick-links,
.social-media,
.legal-info {
  animation: fadeInUp 1s forwards;
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
