.navbar {
  background-color: rgba(153, 217, 255, 0.1); /*Light Accent Color*/
  animation: fadeInDown 0.5s ease forwards;
  padding: 1rem auto;
}

.navbar-brand img {
  animation: fadeInLeft 0.5s ease forwards;
}

.nav-link-list {
  list-style: none;
  padding: 0;
  margin: 0;
  animation: fadeInRight 0.5s ease forwards;
}

.nav-links {
  display: inline-block;
  margin-right: 4rem;
  text-decoration: none;
  font-size: 1rem;
  color: var(--dark-primary-color);
  font-weight: 600;
  letter-spacing: 0.15rem;
  text-shadow: 0.3rem 0.3rem 0.4rem rgba(0, 0, 0, 0.3);
  position: relative;
}
.nav-links:hover {
  transform: scale(1.3) translateX(-0.5rem) translateY(-0.5rem);
  color: var(--dark-secondary-color);
}
.nav-links::after {
  content: "";
  position: absolute;
  bottom: -3px; /* Adjust the position of the underline */
  left: 0;
  width: 0; /* Initial width of the underline */
  height: 2px; /* Height of the underline */
  background-color: var(--dark-secondary-color); /* Color of the underline */
  transition: width 0.3s ease; /* Transition for the width */
}

.nav-links:hover::after {
  width: 100%;
  border-radius: 50%;
  border: 1px solid var(--light-primary-color);
}

.toggle-button {
  background: transparent;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
  filter: drop-shadow(0.3rem 0.3rem 0.4rem rgba(0, 0, 0, 0.3));
  animation: fadeInUp 0.5s ease forwards;
}

.toggle-button img {
  width: 2.5rem;
  aspect-ratio: 1;
}
.toggle-button:hover img {
  transition: 0.3s linear;
  transform: rotate(25deg) translateZ(0) scale(1.2);
}

.show-icon,
.hide-icon {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.hide-icon {
  transform: rotate(180deg) translateY(-2px);
}
@media screen and (max-width: 368px) {
  .navbar-brand img {
    width: 3.3rem;
  }
  .nav-link-list {
    display: flex;
    flex-direction: column;
  }
  .nav-link-list .nav-links {
    font-size: 0.8rem;
    margin-right: 0.5rem;
  }
  .toggle-button img {
    width: 2rem;
  }
}

@media screen and (min-width: 369px) and (max-width: 576px) {
  .navbar {
    width: 100%;
  }
  .navbar-brand img {
    width: 4rem;
  }
  .nav-link-list .nav-links {
    font-size: 0.9rem;
    margin-right: 1rem;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .navbar-brand img {
    width: 5rem;
    aspect-ratio: 1;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .navbar-brand img {
    width: 5rem;
  }
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
  .navbar-brand img {
    width: 5rem;
  }
}

@media screen and (min-width: 1201px) {
  .navbar-brand img {
    width: 5rem;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
