.skeleton-row {
    height: 100vh;
}

.skeleton-card {
  width: 100%;
  height: 100%;
  max-width: 350px;
  min-height: 20rem;
  max-height: 40rem;
  border: none;
  background-color: #dadada; 
  border-radius: 1.5rem;
  overflow: hidden; /* Ensure child elements don't overflow */
  margin-bottom: 20px;
  position: relative;
  box-shadow: 4px 8px 10px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.1); /* Drop shadow */
  animation: pulse 1s infinite linear;
}

@keyframes pulse {
  0% {  
    transform: scale(1) translateY(0);
    opacity: 0.5;
  }
  20% {
    transform: scale(1.005) translateY(-0.5%);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.01) translateY(-1%);
    opacity: 1;
  }
  80% {
    transform: scale(1.005) translateY(-0.5%);
    opacity: 0.8;
  }
  100% {
    transform: scale(1) translateY(0);
    opacity: 0.5;
  }
}
