:root {
  /* Colors */
  --primary-color: #9966cc;
  --secondary-color: #66ccff;
  --accent-color: #ff9933;
  --background-color: #f0f5f9;
  --text-color: #333333;

  --light-primary-color: #b598d6; /* Lighter shade of primary color */
  --dark-primary-color: #7f4fb0; /* Darker shade of primary color */
  --light-secondary-color: #aee5ff; /* Lighter shade of secondary color */
  --dark-secondary-color: #4db8e0; /* Darker shade of secondary color */
  --light-accent-color: #ffc299; /* Lighter shade of accent color */
  --dark-accent-color: #cc6600; /* Darker shade of accent color */
  /* 
  For the light variations:

Light primary color: #b599d1 (RGB: 181, 153, 209)
Light secondary color: #99d9ff (RGB: 153, 217, 255)
Light accent color: #ffbf80 (RGB: 255, 191, 128)

For the dark variations:

Dark primary color: #664c99 (RGB: 102, 76, 153)
Dark secondary color: #3385b5 (RGB: 51, 133, 181)
Dark accent color: #cc6600 (RGB: 204, 102, 0) 
*/
  /* Font Families */
  --heading-font: "Poppins", sans-serif;
  --body-font: "Nunito", sans-serif;
}

/* Apply the fonts globally */
body {
  font-family: var(--body-font);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--heading-font);
}

/* Example usage */
.primary-text {
  color: var(--primary-color);
}

.secondary-text {
  color: var(--secondary-color);
}

.accent-text {
  color: var(--accent-color);
}

.background {
  background-color: var(--background-color);
}

/* Apply text color globally */
body {
  background-color: var(--background-color);
}

/* Quote section styling */
.quote-card {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%;
}

.quote-before,
.quote-after {
  flex: 0 0 auto;
}

.quote-text {
  font-size: 1rem;
  margin-bottom: 15px;
}

.quote-author {
  font-style: italic;
}

.tbBlockquote {
  color: var(--dark-secondary-color);
}

.index-page-h1 {
  background: linear-gradient(
    to right,
    var(--light-accent-color),
    var(--dark-accent-color),
    var(--dark-secondary-color),
    var(--dark-secondary-color)
  );
  background-clip: text;
  color: transparent;
  font-weight: bold;
  display: inline-block;
  font-weight: 600;
}
.index-page-h1 img {
  padding-bottom: 1rem;
}

.quote-card-body {
  background: linear-gradient(
    to right,
    var(--text-color),
    var(--dark-primary-color),
    var(--dark-secondary-color),
    var(--text-color)
  );
  background-clip: text;
  color: transparent;
  font-weight: bold;
  display: inline-block;
}
.quote-section-span {
  background: linear-gradient(
    to right,
    var(--dark-accent-color),
    var(--dark-primary-color),
    var(--light-primary-color)
  );
  background-clip: text;
  color: transparent;
  font-weight: bold;
  display: inline-block;

  animation: highlightPoint 2s ease-in infinite alternate;
}

@keyframes highlightPoint {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
    transform: translate(1px, -1px);
    filter: drop-shadow(-2px 2px 2px var(--light-accent-color));
  }
  50% {
    transform: scale (1.2);
    transform: translate(2px, -2px);
    filter: drop-shadow(-4px 4px 4px var(--dark-secondary-color));
  }
  75% {
    transform: scale (1.1);
    transform: translate(1px, -1px);
    filter: drop-shadow(-4px 4px 4px var(--light-primary-color));
  }
  100% {
    transform: scale (1);
    filter: drop-shadow(-2px 2px 2px var(--dark-accent-color));
  }
}
